@charset "UTF-8";
/* CSS Document */

.no-page {
    text-align: center;
    padding: 20px;
    background: #f2f2f2;
}
.no-page h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: center;
    color: #892114;
    font-weight: bold;
}
@media print, screen and (max-width: 768px) {
  .no-page {
      text-align: left;
  }
  .no-page h2 {
      font-size: 1.2rem;
  }
}



section#shopify-section-template--20913576444182__99d72a07-4757-4d62-b9b3-92498cc4d3e8 .section.section-blends.section-full {
    padding-top: 20px;
    padding-bottom: 20px;
}